import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const tabs = ['Живу в своей', 'Сдаю', 'Снимаю'];

const PageWrapper = styled.div`
  margin: 0 auto;
`;

const TabsPanel = styled.div`
  height: 76px;
  background: #453f87;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 10px 0;

  p {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: white;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tab = styled.span`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  padding: 10px 0;
  color: ${props => (props.active ? 'white' : 'rgba(255,255,255,0.5)')};
  cursor: pointer;
  font-weight: bold;

  &:first-of-type {
    margin-right: 68px;
  }
  &:last-of-type {
    margin-left: 68px;
  }

  &:nth-of-type(2) {
    padding: 0 68px;
    border-right: solid 1px #d9dce6;
    border-left: solid 1px #d9dce6;
  }
`;

const HeroSection = styled.section`
  height: 500px;
  background: #f9f9fb;
  box-shadow: 0px 6px 8px rgba(69, 63, 135, 0.15);
  border-radius: 54px;
  margin-bottom: 50px;
`;

const IndexPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleActiveTab = tab => {
    setActiveTab(tab);
  };
  return (
    <Layout>
      <SEO title="" />
      <PageWrapper>
        <TabsPanel>
          <p>Как защитить квартиру и вещи в ней, если вы:</p>
          <TabsContainer>
            {tabs.map((tab, i) => (
              <Tab
                key={tab}
                onClick={() => handleActiveTab(i)}
                active={activeTab === i}
              >
                {tab}
              </Tab>
            ))}
          </TabsContainer>
        </TabsPanel>
        <HeroSection>hero</HeroSection>
      </PageWrapper>
    </Layout>
  );
};

export default IndexPage;
